import React from 'react'
import { Container, Table, ProgressBar, Form } from 'react-bootstrap/';

class StepTwo extends React.Component {
  render() {
    if (this.props.currentStep !== 2) { // Prop: The current step
      return null
    }
    // The markup for the Step 1 UI
    return(
      <div>
        <Container>
          <Form>
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Label className="headers steps_titles">Pick a Exercise</Form.Label>
                <Form.Control as="select" name="selected_exercise" value={this.props.selected_exercise[0]} onChange={this.props.handleChange}>
                  <option>Select an Exercise</option>
                  {this.props.exercises}
                </Form.Control>
            </Form.Group>
          </Form>

        <ProgressBar animated variant="dark" now={50} className="bottom-buffer"/>

          <h2 className="headers steps_titles">Selection Data</h2>
          <Table striped bordered hover variant="dark">
            <thead>
              <tr>
                <th>Course Data</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Course Name</td> 
                <td>{this.props.selected_course[0]}</td>
              </tr>
              <tr>
                <td>Course ID</td>
                <td>{this.props.selected_course[1]}</td>
              </tr>
              <tr>
                <td>Course Homepage</td>
                <td><a href={this.props.selected_course[2]}> {this.props.selected_course[2]}</a></td>
              </tr>
              <tr>
                <td>Exercise Number</td>
                <td>{this.props.selected_exercise[0]}</td>
              </tr>
              <tr>
                <td>Exercise Name (if Applicable)</td>
                <td>{this.props.selected_exercise[1]}</td>
              </tr>
              <tr>
                <td>Exercise Question</td>
                <td>{this.props.selected_exercise[3]}</td>
              </tr>
              <tr>
                <td>Exercise URL</td>
                <td><a href={this.props.selected_exercise[2]}> {this.props.selected_exercise[2]}</a></td>
              </tr>
              <tr>
                <td>Grade</td>
                <td>{this.props.selected_grade}</td>
              </tr>
            </tbody>
          </Table>

        </Container>
      </div>
    )
  }
}
export default StepTwo


