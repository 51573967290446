import React, { useContext } from "react";
import { Router } from "@reach/router";
import FeedbackGenerator from "./steps/feedbackGenerator"
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import PasswordReset from "./PasswordReset"
import Settings from "./Settings"
import MLSandbox from "./MLSandbox"
import '../stylesheets/App.css';
import { UserContext } from "../providers/UserProvider";


function Application() {
  const user = useContext(UserContext);
  return (
       user ?
        <FeedbackGenerator />
      :
       <Router>
         <SignIn path="signIn" />
         <SignUp path="signUp" />
         <PasswordReset path = "PasswordReset" />
         <Settings path="settings" />
         <FeedbackGenerator path="FeedbackGenerator" />
         <MLSandbox path="MLSandbox" />
       </Router>

  );
}

export default Application;
