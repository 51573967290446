import React from 'react';
import { Navbar, Nav } from 'react-bootstrap/';

function Navi() {
  return (
      <Navbar bg="light" sticky="top">
        <Navbar.Brand href="">
          <img className="logo" src={require(".././hbe_ventures_SCPD_logo.png")} alt="Logo for the SCPD Grader Web app provided by HBE." />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Nav className="mr-auto">
          <Nav.Link href="">Generator</Nav.Link>
          <Nav.Link href="">Input Feedback</Nav.Link>
          <Nav.Link href="">Settings</Nav.Link>
          <Nav.Link href="">ML Sandbox</Nav.Link>
        </Nav>
      </Navbar>
  );
}

export default Navi;
