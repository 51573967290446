import React from "react";
import Application from "../components/Application";
import UserProvider from "../providers/UserProvider";
import '../stylesheets/App.css';
import Navi from './nav'
import { Container, Jumbotron } from 'react-bootstrap/';


function App() {
  return (
    <UserProvider>
        <Navi />
        	<Jumbotron>
        		<h1 className="header text-center">Welcome to the SCPD Grading App</h1>
        	</Jumbotron>
            <Container>
                <Application />
            </Container>
    </UserProvider>
  );
}

export default App;
