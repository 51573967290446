import React, {useState} from "react";
import { Link } from "@reach/router";
import { signInWithGoogle } from "../firebase";
import { auth } from "../firebase";
import { Input } from "reactstrap";
import '../stylesheets/App.css';
import { Container, Button, Form, FormGroup } from 'react-bootstrap/';


const SignIn = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const signInWithEmailAndPasswordHandler = (event,email, password) => {
        event.preventDefault();
        auth.signInWithEmailAndPassword(email, password).catch(error => {
        setError("Error signing in with password and email!");
          console.error("Error signing in with password and email", error);
        });
      };

      const onChangeHandler = (event) => {
          const {name, value} = event.currentTarget;

          if(name === 'userEmail') {
              setEmail(value);
          }
          else if(name === 'userPassword'){
            setPassword(value);
          }
      };


  return (
    <div className="border rounded">
    <Container>
      <h1 className="text-center headers">Sign In</h1>
      <div>
        {error !== null && <div className = "py-4 bg-red-600 w-full text-white text-center mb-3">{error}</div>}
        <Form className="login">
        <FormGroup>
          <Input
            type="email"
            className="my-1 p-1 w-full"
            name="userEmail"
            value = {email}
            placeholder="E.g: Stanford@hbe.io"
            id="userEmail"
            onChange = {(event) => onChangeHandler(event)}
          />
        </FormGroup>
        <FormGroup>
          <Input
            type="password"
            className="mt-1 mb-3 p-1 w-full"
            name="userPassword"
            value = {password}
            placeholder="Your Password"
            id="userPassword"
            onChange = {(event) => onChangeHandler(event)}
          />
        </FormGroup>

        <Button onClick = {(event) => {signInWithEmailAndPasswordHandler(event, email, password)}}>
          Sign In
        </Button>
        
        <Button onClick={() => { signInWithGoogle();}}>
          Sign in with Google
        </Button>
        
        <p className="text-center my-3">
          <Link to="passwordReset" className="hover:text-white-600">
            Forgot Password?
          </Link>
        </p>

      </Form>
      </div>
      </Container>
    </div>
  );
};

export default SignIn;
