import React from "react";
import StepOne from './stepOne'
import StepTwo from './stepTwo'
import StepThree from './stepThree'
import StepFour from './stepFour'
import '../../stylesheets/App.css';
import {database} from "../../firebase";

class FeedbackGenerator extends React.Component {
// Defining the constructor class.
  constructor(props) {
    super(props)
    // Bind new functions for next and previous
    this._next = this._next.bind(this)
    this._prev = this._prev.bind(this)
    this.state = {
      currentStep: 1, // Default is Step 1
      courses: ['Select a Course'],
      exercises: [],
      selected_course: ["","",""],
      selected_exercise: ["","","",""],
      selected_grade: "",
      generated_feedback: ""
    }
    // Bind the submission to handleChange()
    this.handleChange = this.handleChange.bind(this);
    this.handleExerciseSelection = this.handleExerciseSelection.bind(this);
    this.handleGetExerciseData = this.handleGetExerciseData.bind(this);
    this.handleFeedbackResponse = this.handleFeedbackResponse.bind(this);
  }
  // Test current step with ternary
  // _next and _previous functions will be called on button click
  _next() {
    let currentStep = this.state.currentStep
  // If the current step is 1 or 2, then add one on "next" button click
    currentStep = currentStep >= 4? 4: currentStep + 1
    this.setState({
      currentStep: currentStep
    })
  }

// Function for the previous step.
  _prev() {
    let currentStep = this.state.currentStep
    // If the current step is 2, 3, or 4, then subtract one on "previous" button click
    currentStep = currentStep <= 1? 1: currentStep - 1
    this.setState({
      currentStep: currentStep
    })
  }

// Defining and setting up the "next" and "previous" button functions.
  get previousButton(){
    let currentStep = this.state.currentStep;
    // If the current step is not 1, then render the "previous" button
    if(currentStep !==1){
      return (
        <button
          className="btn btn-secondary"
          type="button" onClick={this._prev}>
        Previous
        </button>
      )
    }
    // ...else return nothing
    return null;
  }

// Defining the next button and its function.
  get nextButton() {
    let currentStep = this.state.currentStep;
    // If the current step is not 4, then render the "next" button
    if(currentStep <4){
      return (
        <button className="btn btn-primary float-right"
          type="button" onClick={this._next}>
            Next
        </button>
          )
    } 
  }

// Use the submitted data to set the state
  handleChange(event) {
    const {name, value} = event.target
    this.setState({
      [name]: [value]
    })
  }

// HANDLER: Exercise Selection
  // Grabbing an initial pull of the possible exercises by Course.
  handleExerciseSelection(e) {
    this.handleChange(e)
    // eslint-disable-next-line
    var query_term = e.target.value 

  // Here we should call the database, get the json of the course & exercise data, and plop it below.
    let url= "https://scpd-app.firebaseio.com/courses.json"
    fetch(url)
      .then(resp => resp.json())
      .then(data => {
        let c = data.filter(course => course.course_name === query_term)
        let d = c[0].exercises.map((exercise, index) => {
          return (
            <option value={exercise.exercise_number}>{exercise.exercise_number}</option>
          )
        })
        // Storing course data.
        this.setState({selected_course: [c[0].course_name,c[0].course_id,c[0].course_homepage]})

        this.setState({exercises: d});
      })
  }

// // Here, we handle the button click and get the selected exercise, and populate the data with it.
  handleGetExerciseData(e) {
    this.handleChange(e)
    const query_term = e.target.value 
    Promise.resolve( database.ref("courses").orderByChild("course_name").equalTo(this.state.selected_course[0]).once('value')
        .then(s => {
          var d = s.toJSON ()
          console.log(d)
          d = d[Object.keys(d)[0]].exercises;
          return d
        })
      )
      .then(d => {
        for (var i in d ) {
          if (d[i].exercise_number.toString() === this.state.selected_exercise[0].toString()) {           
            var selected_exercise_data = { "selected_exercise": [d[i].exercise_number,d[i].exercise_name,d[i].exercise_url,d[i].exercise_question,d[i].exercise_id]}
            return selected_exercise_data            
          }
        }
      })
      .then(s => {
        this.setState({
          selected_exercise: s.selected_exercise
        })
      })
  }
// HANDLER: Request and parse random feedback
  handleFeedbackResponse(e){

  // Setup a sampling method for use later.
  function sample(array) {
    return array[Math.floor ( Math.random() * array.length )]
  }
  // this.handleChange(e)
    // Compile feedback response
    Promise.resolve(database.ref("historical_exercise_answers").orderByChild("exercise_id").equalTo(this.state.selected_exercise[4]).once('value'))
    .then(s => {
        var d = s.toJSON()
        var data = {
          "course_intro": [],
          "main_responses": [],
          "bad_followup": [],
          "lessons_leanred":[],
          "followup": [],
          "closing": [],
          "course_farewell": [],
          "resubmit": []
        }
        var newLine = "\r\n\n";

        Object.entries(d).forEach(([key, value]) => {     
          var vc = value.category
          // Store all values categorically.
          if ( data.hasOwnProperty(vc)) {
            data[vc].push(value.content)
          }

          // Store Main Responses *exception because of type.
          if ( vc === this.state.selected_grade) {
            data.main_responses.push(value.content)
            if ( this.state.selected_grade === "bad" && vc ) {
                data.main_responses.push(value.content)
            }
          } 

        });
        var response = []
        Object.entries(data).forEach(([key, value]) => {
          console.log(value)
          if (value.length) {
            // array empty or does not exist
            response.push(sample(value)) 
          }          
        })
        // eslint-disable-next-line
        var signature = newLine + "Best," + "\n" + "Austin Hay" + "\n" + "Member of the Teaching Team" + "\n" + "Stanford Innovation and Entrepreneurship Certificate"
        return response.join(newLine) + signature
      })
      .then(s => {
        this.setState({
          generated_feedback: s
        })
      })
  }

// HANDLER: Load Initial Course List
  componentDidMount() {
    let url= "https://scpd-app.firebaseio.com/courses.json"
    fetch(url)
      .then(resp => resp.json())
      .then(data => {
        let active_courses = data.filter(course => course.course_status === "active")
          .map((course, index) => {
            return (
              <option value={course.course_name}>{course.course_name}</option>
            )
          })
        this.setState({courses: active_courses});
      })
  }

// HANDLER: Reset to step 1
  handleStartOver(event) {
    this.setState({
      currentStep: 1
    })
  }


  render() {
    return (
      <div>
          <StepOne
            currentStep={this.state.currentStep}
            handleChange={this.handleExerciseSelection}
            courses={this.state.courses}
            selected_course={this.state.selected_course}
          />
          <StepTwo
            currentStep={this.state.currentStep}
            handleChange={this.handleGetExerciseData}
            courses={this.state.courses}
            exercises={this.state.exercises}
            selected_course={this.state.selected_course}
            selected_exercise={this.state.selected_exercise}
          />
          <StepThree
            currentStep={this.state.currentStep}
            handleChange={this.handleChange}
            courses={this.state.courses}
            exercises={this.state.exercises}
            selected_course={this.state.selected_course}
            selected_exercise={this.state.selected_exercise}
            selected_grade={this.state.selected_grade}
          />
        <StepFour
            currentStep={this.state.currentStep}
            handleChange={this.handleChange}
            courses={this.state.courses}
            exercises={this.state.exercises}
            selected_course={this.state.selected_course}
            selected_exercise={this.state.selected_exercise}
            selected_grade={this.state.selected_grade}
            generated_feedback={this.state.generated_feedback}
            handleFeedbackResponse={this.handleFeedbackResponse}
            handleStartOver={this.handleStartOver}
          />
          {this.previousButton}
          {this.nextButton}
      </div>
    )
  }
}

export default FeedbackGenerator;
