import React from 'react'
import { Container, Table, ProgressBar, Form } from 'react-bootstrap/';

class StepOne extends React.Component {
  render() {
    if (this.props.currentStep !== 1) { // Prop: The current step
      return null
    }

    // The markup for the Step 1 UI
    return(
      <div>
        <Container>
          <Form>
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Label className="headers steps_titles">Pick a Course</Form.Label>
                <Form.Control as="select" name='selected_course' value={this.props.selected_course[0]} onChange={this.props.handleChange}>
                  <option>Select a Course</option>
                  {this.props.courses}
                </Form.Control>
            </Form.Group>
          </Form>     

          <ProgressBar animated variant="dark" now={25} className="bottom-buffer"/>

          <h2 className="headers steps_titles">Selection Data</h2>
          <Table striped bordered hover variant="dark">
            <thead>
              <tr>
                <th>Course Data</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Course Name</td> 
                <td>{this.props.selected_course[0]}</td>
              </tr>
              <tr>
                <td>Course ID</td>
                <td>{this.props.selected_course[1]}</td>
              </tr>
              <tr>
                <td>Course Homepage</td>
                <td><a href={this.props.selected_course[2]}> {this.props.selected_course[2]}</a></td>
              </tr>
            </tbody>
          </Table>
          
        </Container>
      </div>
    )
  }
}
export default StepOne
