import React from 'react'
import { Jumbotron, Navbar, Container, Table, ProgressBar, Form, FormGroup, FormControl, FormLabel, Button } from 'react-bootstrap/';

class StepFour extends React.Component {
  render() {
    if (this.props.currentStep !== 4) { // Prop: The current step
      return null
    }

  function copy() {
    var textarea = document.getElementsByClassName("feedbackTextArea")[0].value
      console.log(textarea)
    var temp = document.createElement('input')
    temp.value = textarea
    temp.select();
    document.execCommand("copy");
    temp.remove();
  }

    return(
    <div>
      <Container>
          <Form>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label className="headers steps_titles">Student Grade & Feedback</Form.Label>
                  <Form.Control as="textarea" rows="10" className="feedbackTextArea" value={this.props.generated_feedback}/>
              </Form.Group>
          </Form>

          <Button variant="dark" onClick={this.props.handleFeedbackResponse}>
              Shuffle Feedback
          </Button>

          <Button variant="success" onClick={copy}>
            Copy
          </Button>

        <ProgressBar animated variant="dark" now={100} className="bottom-buffer"/>

          <h2 className="headers steps_titles">Selection Data</h2>
          <Table striped bordered hover variant="dark">
            <thead>
              <tr>
                <th>Course Data</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Course Name</td> 
                <td>{this.props.selected_course[0]}</td>
              </tr>
              <tr>
                <td>Course ID</td>
                <td>{this.props.selected_course[1]}</td>
              </tr>
              <tr>
                <td>Course Homepage</td>
                <td><a href={this.props.selected_course[2]}> {this.props.selected_course[2]}</a></td>
              </tr>
              <tr>
                <td>Exercise Number</td>
                <td>{this.props.selected_exercise[0]}</td>
              </tr>
              <tr>
                <td>Exercise Name (if Applicable)</td>
                <td>{this.props.selected_exercise[1]}</td>
              </tr>
              <tr>
                <td>Exercise Question</td>
                <td>{this.props.selected_exercise[3]}</td>
              </tr>
              <tr>
                <td>Exercise URL</td>
                <td><a href={this.props.selected_exercise[2]}> {this.props.selected_exercise[2]}</a></td>
              </tr>
              <tr>
                <td>Grade</td>
                <td>{this.props.selected_grade}</td>
              </tr>
            </tbody>
          </Table>

        </Container>
      </div>
    )
  }
}

export default StepFour
