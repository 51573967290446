import React from "react";

class MLSandbox extends React.Component {
  	constructor(props) {
    super(props)
	}



render() {
	return(
		<div>
		</div>
	)
}

}

export default MLSandbox;